import React from "react";
import Navigation from '../components/Navigation'

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Badge,
    Button
} from "shards-react";

export default class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            PostsListOne: [
                {
                    backgroundImage: require("../images/6.jpeg"),
                    category: "Business",
                    categoryTheme: "dark",
                    author: "Anna Kunis",
                    link: "/object",

                    title: "Проверка объектов",
                    body:
                        "В разделе проверяются юридическая чистота объекта недвижимости и благонадёжность продавца",
                    date: "28 February 2019"
                },
                {
                    backgroundImage: require("../images/13.jpeg"),
                    category: "Travel",
                    categoryTheme: "info",
                    author: "James Jamerson",
                    link: 'buyer',

                    title: "Проверка покупателей",
                    body:
                        "В разделе проверяется благонадёжность покупателя объекта",
                    date: "29 February 2019"
                },
                {
                    backgroundImage: require("../images/14.jpeg"),
                    category: "Technology",
                    categoryTheme: "royal-blue",
                    author: "Jimmy Jackson",
                    link: '/analytcis',

                    title: "Коммерческая информация об объекте",
                    body:
                        "Аналитическая информация по выбранным объектам недвижимости",
                    date: "29 February 2019"
                }
            ]
        }
    };

    render() {
        const {
            PostsListOne
        } = this.state;

        return (
            <div>
                <Navigation></Navigation>
                <Container fluid>
                    <Row>
                        <Col
                            className="main-content p-0"
                            lg={{ size: 10, offset: 1 }}
                            md={{ size: 9, offset: 3 }}
                            sm="12"
                            tag="main"
                        >
                            <Container>
                                <Row>
                                    {PostsListOne.map((post, idx) => (
                                        <Col lg="4" className="mb-4" key={idx}>
                                            <Card small className="card-post mb-4">
                                                <div
                                                    className="card-post__image"
                                                    style={{ backgroundImage: `url(${post.backgroundImage})` }}
                                                >
                                                </div>
                                                <CardBody>
                                                    <h5 className="card-title">
                                                        <a href={post.link} className="text-fiord-blue">
                                                            {post.title}
                                                        </a>
                                                    </h5>
                                                    <p className="card-text d-inline-block mb-3">{post.body}</p>
                                                    
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

        )
    }
}