import React from 'react';
import { Link, Redirect } from "react-router-dom";
import StitchServices from '../services/stitch';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Form,
    FormGroup,
    FormInput,
    FormCheckbox,
    Button
} from 'shards-react';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.userInput = this.userInput.bind(this);
        this.auth = this.auth.bind(this);
        this.state = {
            email: '',
            pwd: '',
            redirectToHome: false
        }
    };

    userInput(event) {
        switch (event.target.id) {
            case 'email':
                this.setState({
                    email: event.target.value
                });
                break
            case 'pwd':
                this.setState({
                    pwd: event.target.value
                });
                break
            default:
                break
        }
    };

    auth() {
        this._asyncRequest = StitchServices.loginUser(this.state.email, this.state.pwd).then(
            externalData => {
                if (externalData === 'success') {
                    this.setState({
                        redirectToHome: true
                    })
                }
            }
        )
    };

    render() {
        if (this.state.redirectToHome) return <Redirect to='/home' />;

        return (
            <Container fluid className="icon-sidebar-nav">
                <Row>
                    <Col className="main-content col" tag="main">
                        <Container fluid className="main-content-container h-100 px-4">
                            <Row noGutters className="h-100">
                                <Col lg="3" md="5" className="auth-form mx-auto my-auto">
                                    <Card>
                                        <CardBody>
                                            {/* Logo */}
                                            <img
                                                className="auth-form__logo d-table mx-auto mb-3"
                                                src={require("../logo.svg")}
                                                alt="Shards Dashboards - Login Template"
                                            />
                                            {/* Title */}
                                            <h5 className="auth-form__title text-center mb-4">Инесса</h5>
                                            {/* Form Fields */}
                                            <Form>
                                                <FormGroup>
                                                <label htmlFor="email">Адрес электронной почты</label>
                                                    <FormInput
                                                        type="email"
                                                        id="email"
                                                        value={this.state.login} onChange={this.userInput}
                                                        placeholder="Электронная почта"
                                                        autoComplete="email"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                <label htmlFor="pwd">Пароль</label>
                                                    <FormInput
                                                        id="pwd" type="password" value={this.state.pwd} onChange={this.userInput}
                                                        placeholder="Пароль"
                                                        autoComplete="current-password"
                                                    />
                                                </FormGroup>
                                                <Button  theme="accent" className="d-table mx-auto" onClick={this.auth}>Войти</Button>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                    {/* Meta Details */}
                                    <div className="auth-form__meta d-flex mt-4">
                                        <Link to="/forgot-password">Забыли свой пароль?</Link>
                                        <Link to="/register" className="ml-auto">Зарегистрироваться?</Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        )
    }
}