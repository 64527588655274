import React from 'react';
import Navigation from '../components/Navigation';
import {
    Container,
    Row,
    Col
} from "shards-react";
import analytics_1 from '../images/analytics_1.png';
import general_data from '../images/general_data.JPG';
import caprepair_data from '../images/caprepair_data.JPG';

export default class Analytics extends React.Component {
    constructor(props)
    {
    super(props);
}

    render() {
        return (
            <div>
                <Navigation></Navigation>
                <Container>
                    <Row>
                        <Col>
                        <img src={analytics_1} width="800"></img>
                        </Col>
                        <Col>
                        <h4>Аналитика рынка</h4>
                        <p>Показываем статистику по изменению стоимости объектов, ближайшие магазины, больницы, школы и т.д.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <h4>Статистика по выбранному объекту</h4>
                        <p>Показываем общую информацию про выбранный объект - когда был построен, лифты, этажность и т.д.</p> 
                        </Col>
                        <Col>
                        <img src={general_data} width="800"></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <img src={caprepair_data} width="800"></img>
                        </Col>
                        <Col>
                        <h4>Информация о кап ремонтах</h4>
                        <p>Можно посмотреть, когда и какие работы были проведены, когда ближайший кап ремонт</p>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
};
