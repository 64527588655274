import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    FormInput,
    FormCheckbox,
    Button
} from 'shards-react';
import { Link, Redirect } from "react-router-dom";
import { Stitch, UserPasswordAuthProviderClient } from 'mongodb-stitch-browser-sdk';
import StitchServices from '../services/stitch';

export default class Register extends React.Component {
    constructor(props) {
        super(props);
        this.userInput = this.userInput.bind(this);
        this.register = this.register.bind(this);
        this.state = {
            email: '',
            pwd: '',
            pwd_repeat: '',
            redirentToHome: false
        }
    }

    userInput(event) {
        switch (event.target.id) {
            case 'email':
                this.setState({
                    email: event.target.value
                });
                break
            case 'pwd':
                this.setState({
                    pwd: event.target.value
                });
                break
            case 'pwd_repeat':
                this.setState({
                    pwd_repeat: event.target.value
                });
                break
        }
    }

    register() {
        this._asyncRequest = StitchServices.registerUser(this.state.email, this.state.pwd).then(
            externalData => {
                if (externalData === 'success') {
                    this.setState({
                        redirectToHome: true
                    })
                }
            }
        )        
    }

    render() {
        if (this.state.redirectToHome) return <Redirect to='/home' />;
        
        return (
            <Container fluid className="icon-sidebar-nav">
                <Row>
                    <Col className="main-content col" tag="main">
                        <Container fluid className="main-content-container h-100 px-4">
                            <Row noGutters className="h-100">
                                <Col lg="3" md="5" className="auth-form mx-auto my-auto">
                                    <Card>
                                        <CardBody>
                                            {/* Logo */}
                                            <img
                                                className="auth-form__logo d-table mx-auto mb-3"
                                                src={require("../logo.svg")}
                                                alt="Shards Dashboards - Register Template"
                                            />
                                            {/* Title */}
                                            <h5 className="auth-form__title text-center mb-4">Регистрация</h5>
                                            {/* Form Fields */}
                                            <Form>
                                                <FormGroup>
                                                <label htmlFor="email">Электронная почта</label>
                                                    <FormInput
                                                        type="email"
                                                        id="email" value={this.state.email} onChange={this.userInput}
                                                        placeholder="Введите адрес электронной почты"
                                                        autoComplete="email"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                <label htmlFor="pwd">Пароль</label>
                                                    <FormInput
                                                        type="password"
                                                        id="pwd" value={this.state.pwd} onChange={this.userInput}
                                                        placeholder="Пароль"
                                                        autoComplete="new-password"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                <label htmlFor="pwd_repeat">Повторите пароль</label>
                                                    <FormInput
                                                        type="password"
                                                        id="pwd_repeat" value={this.state.pwd_repeat} onChange={this.userInput}
                                                        placeholder="Повторите пароль"
                                                        autoComplete="new-password"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormCheckbox>
                                                        Я соглашаюсь с <a href="/conditions">Условиями</a>.
                </FormCheckbox>
                                                </FormGroup>
                                                <Button theme="accent" className="d-table mx-auto" onClick={this.register}>Зарегистрироваться</Button>
                                            </Form>
                                        </CardBody>
                                    </Card>

                                    {/* Meta Details */}
                                    <div className="auth-form__meta d-flex mt-4">
                                        <Link to="/forgot-password">Забыли пароль?</Link>
                                        <Link to="/login" className="ml-auto">Войти?</Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>



        )
    }
}