import React from 'react';
import Navigation from '../components/Navigation';
import {
    ListGroup,
    ListGroupItem,
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    CardSubtitle,
    CardHeader,
    CardBody,
    CardFooter,
    Badge,
    Button,
    FormCheckbox,
    ButtonGroup,
    FormSelect,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "shards-react";
import StitchServices from '../services/stitch';

export default class BuyerCheck extends React.Component {
    constructor(props) {
        super(props);
        this.toggleDetails = this.toggleDetails.bind(this);
        this.state = {
            userData: {
                buyerName: 'Елсуков Кирилл Андреевич',
                dateAdded: '24.07.2019',
                buyerPassportSeries: '1234',
                buyerPassportNumber: '567890',
                buyerIssueDate: '01.01.1999',
                buyerIssueBody: 'Москва',



                tags: [
                    "User Experience",
                    "UI Design",
                    "React JS",
                    "HTML & CSS",
                    "JavaScript",
                    "Bootstrap 4"
                ]
            },
            checksData: null,
            toggleDetails: false
        }
    }

    toggleDetails(event) {
        this.setState({
            toggleDetails: !this.state.toggleDetails
        });
        if (typeof (event) !== 'undefined') console.log(event.target.id);
    };

    componentDidMount() {
        this._asyncRequest = StitchServices.getBuyersChecks().then(
            externalData => {
                this._asyncRequest = null;
                this.setState({
                    checksData: externalData
                })
            }
        );
    };

    componentWillUnmount() {
        if (this._asyncRequest) {
            this._asyncRequest.cancel();
        }
    };

    render() {
        if (this.state.checksData === null) {
            return (
                <div>
                    <Navigation></Navigation>
                </div>
            )
        } else {
            return (
                <div>
                    <Navigation></Navigation>
                    <Container fluid className="main-content-container px-4">
                        <Row>
                            <Col lg="4">
                                <Card small className="mb-4 pt-3">
                                    <CardHeader className="border-bottom">
                                        <h6 className="m-0">Детали покупателя</h6>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                        <div className="user-details__user-data border-top border-bottom p-4">
                                            <Row className="mb-3">
                                                <Col className="w-50">
                                                    <span>ФИО</span>
                                                    <span>{this.state.userData.buyerName}</span>
                                                </Col>
                                                <Col className="w-50">
                                                    <span>Дата добавления</span>
                                                    <span>{this.state.userData.dateAdded}</span>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col className="w-50">
                                                    <span>Серия паспорта</span>
                                                    <span>{this.state.userData.buyerPassportSeries}</span>
                                                </Col>
                                                <Col className="w-50">
                                                    <span>Номер паспорта</span>
                                                    <span>{this.state.userData.buyerPassportNumber}</span>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col className="w-50">
                                                    <span>Дата выдачи паспорта</span>
                                                    <span>{this.state.userData.buyerIssueDate}</span>
                                                </Col>
                                                <Col className="w-50">
                                                    <span>Кем выдан паспорт</span>
                                                    <span>{this.state.userData.buyerIssueBody}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                        {/* User Tags */}
                                        <div className="user-details__tags p-4">
                                        <Row className="mb-3">
                                                <Col className="w-50">
                                                    <span>Результат: </span>
                                                    <span>с точки зрения Кардиф данный покупатель может быть застрахован</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>


                            </Col>
                            <Col lg="8">
                                <Card small className="mb-4">
                                    <CardHeader className="border-bottom">
                                        <h6 className="m-0">Чеклист</h6>

                                    </CardHeader>

                                    <CardBody className="p-0">
                                        <Container fluid className="px-0">
                                            <table className="table mb-0">
                                                <thead className="py-2 bg-light text-semibold border-bottom">
                                                    <tr>
                                                        <th className="text-center">Проверка</th>
                                                        <th className="text-center">Результат</th>
                                                        <th className="text-center">Тип</th>
                                                        <th className="text-center">Действия</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.checksData.map((item, idx) => (
                                                        <tr key={idx}>

                                                            <td className="lo-stats__order-details">
                                                                <span>{item.name}</span>
                                                                <span>{item.class}</span>
                                                            </td>
                                                            <td className="lo-stats__status">
                                                                <div className="d-table mx-auto">
                                                                    <Badge pill theme={getBadgeType(item.result)}>
                                                                        {item.result}
                                                                    </Badge>
                                                                </div>
                                                            </td>
                                                            <td className="lo-stats__items text-center">{item.type}</td>
                                                            <td className="lo-stats__actions">
                                                                <ButtonGroup className="d-table ml-auto">
                                                                    <Button size="sm" theme="white">Подтвердить</Button>
                                                                    <Button size="sm" theme="white" onClick={this.toggleDetails} id={item.name}>Подробнее</Button>
                                                                </ButtonGroup>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Container>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    <Modal open={this.state.toggleDetails} toggle={this.toggleDetails} size="lg">
                        <ModalHeader>
                            Детали проверки
                    </ModalHeader>
                        <ModalBody>
                            <ListGroup small flush className="list-group-small">
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Зачем нужна проверка</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">Описываем ценность проверки - какие риски закрывает, что даёт</span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Результаты проверки</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">Показываем текущий результат проверки</span>
                                </ListGroupItem>
                            </ListGroup>
                        </ModalBody>
                        <ModalFooter>
                        <Button theme="primary" onClick={this.toggleDetails}>Готово</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            )
        }
    }
};

/*
                    <Row className="mt-4">
                        <Col lg="6" sm="12">
*/

function getBadgeType(itemStatus) {
    var result;
    switch (itemStatus) {
        case 'Проверено': result = 'success';
            break
        case 'В процессе': result = 'warning';
            break
        case 'Надо сделать': result = 'danger';
            break
        default: result = 'primary';
            break
    }
    return result;
}