import React from 'react';

import {
    Button,
    Collapse,
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Nav,
    NavItem,
    NavLink,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    FormInput
} from "shards-react";

import { ReactDadata } from "react-dadata";

const rowStyle = {
    marginBottom: '10px'
};

export default class Deal extends React.Component {
    constructor(props) {
        super(props);
        this.objectSelected = this.objectSelected.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.formChanged = this.formChanged.bind(this);
        this.showObject = this.showObject.bind(this);
        this.showSeller = this.showSeller.bind(this);
        this.showBuyer = this.showBuyer.bind(this);


        this.state = {
            objectCollapse: true,
            objectSelected: false,
            sellerCollapse: false,
            sellerSelected: false,
            buyerCollapse: false,
            buyerSelected: false,
            objectValue: '',
            sellerName: '',
            sellerPassportSeries: '',
            sellerPassportNumber: '',
            sellerIssueDate: '',
            sellerIssueBody: '',
            buyerName: '',
            buyerPassportSeries: '',
            buyerPassportNumber: '',
            buyerIssueDate: '',
            buyerIssueBody: ''
        };
    }

    objectSelected(text) {
        this.setState({
            objectSelected: true,
            objectCollapse: false,
            sellerCollapse: true,
            objectValue: text

        });
    };

    nextStep() {
        this.setState({
            sellerSelected: true,
            sellerCollapse: false,
            buyerCollapse: true
        });
    };

    formChanged(event) {
        switch (event.target.id) {
            case 'sellerName':
                this.setState({ sellerName: event.target.value });
                break
            case 'sellerPassportSeries':
                this.setState({sellerPassportSeries: event.target.value });
                break
            case 'sellerPassportNumber':
                this.setState({ sellerPassportNumber: event.target.value });
                break
            case 'sellerIssueDate':
                this.setState( { sellerIssueDate: event.target.value });
                break
            case 'sellerIssueDate':
                this.setState( { sellerIssueDate: event.target.value });
                break
            case 'sellerIssueBody':
                this.setState({ sellerIssueBody: event.target.value });
                break
            case 'buyerName':
                this.setState({ buyerName: event.target.value });
                break
            case 'buyerPassportSeries':
                this.setState({ buyerPassportSeries: event.target.value });
                break
            case 'buyerPassportNumber':
                this.setState( { buyerPassportNumber: event.target.value });
                break
            case 'buyerIssueDate':
                this.setState({ buyerIssueDate: event.target.value });
                break
            case 'buyerIssueDate':
                this.setState({ buyerIssueDate: event.target.value });
                break
            case 'buyerIssueBody':
                this.setState({ buyerIssueBody: event.target.value });
                break
        }

        console.log(this.state);

    }

    showObject() {
        this.setState(
            {
                objectCollapse: true,
                sellerCollapse: false,
                buyerCollapse: false
            }
        )
    };

    showSeller() {
        this.setState(
            {
                objectCollapse: false,
                sellerCollapse: true,
                buyerCollapse: false
            }
        )
    };

    showBuyer() {
        this.setState(
            {
                objectCollapse: false,
                sellerCollapse: false,
                buyerCollapse: true
            }
        )
    };

    render() {
        return (
            <Container>
                <Row style={rowStyle}>
                    <Col>
                        <Card>
                            <CardHeader>
                                <CardTitle>Сделка №1</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Nav pills justified>
                                    <NavItem>
                                        <NavLink active={this.state.objectCollapse} href='#' onClick={this.showObject}>Объект</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={this.state.sellerCollapse} href='#' onClick={this.showSeller}>Покупатель</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={this.state.buyerCollapse} href='#' onClick={this.showBuyer}>Продавец</NavLink>
                                    </NavItem>
                                </Nav>
                                <Collapse open={this.state.objectCollapse} style={rowStyle}>
                                    <h3>Введите адрес объекта</h3>
                                    <ReactDadata token="2b22eae11193756514c4e83ab149c7a484a726a2" query="Москва" placeholder="" onChange={this.objectSelected} value={this.state.objectValue}></ReactDadata>
                                </Collapse>
                                <Collapse open={this.state.sellerCollapse}>

                                    <h3>Проверка продавца</h3>
                                    <Row style={rowStyle}>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>ФИО</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='sellerName' onChange={this.formChanged} value={this.state.sellerName} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={rowStyle}>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Серия паспорта</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='sellerPassportSeries' onChange={this.formChanged} value={this.state.sellerPassportSeries} />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Номер паспорта</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='sellerPassportNumber' onChange={this.formChanged} value={this.state.sellerPassportNumber} />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Дата выдачи</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='sellerIssueDate' onChange={this.formChanged} value={this.state.sellerIssueDate} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={rowStyle}>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Выдан</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='sellerIssueBody' onChange={this.formChanged} value={this.state.sellerIssueBody} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Collapse>
                                <Collapse open={this.state.buyerCollapse}>

                                    <h3>Проверка покупателя</h3>
                                    <Row style={rowStyle}>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>ФИО</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='buyerName' onChange={this.formChanged} value={this.state.buyerName} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={rowStyle}>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Серия паспорта</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='buyerPassportSeries' onChange={this.formChanged} value={this.state.buyerPassportSeries} />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Номер паспорта</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='buyerPassportNumber' onChange={this.formChanged} value={this.state.buyerPassportNumber} />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Дата выдачи</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='buyerIssueDate' onChange={this.formChanged} value={this.state.buyerIssueDate} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={rowStyle}>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Выдан</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='buyerIssueBody' onChange={this.formChanged} value={this.state.buyerIssueBody} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Collapse>
                                <Row style={rowStyle}>
                                    <Col>
                                        <Button theme="primary" onClick={this.nextStep}>Подтвердить</Button>
                                        <Button theme="success" disabled={!this.state.buyerCollapse}>Готово</Button>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}