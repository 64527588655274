import React from 'react';
import {
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Collapse
} from 'shards-react';

import { createBrowserHistory } from "history";

const customHistory = createBrowserHistory();

export default class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapseOpen: false,
            menu: [
                { text: 'Объект - проверки', route: '/object', isActive: false },
                { text: 'Покупатель - проверки', route: '/buyer', isActive: false },
                { text: 'Информация об объекте', route: '/analytics', isActive: false}
            ]
        };
    };

    componentDidMount() {
        switch (customHistory.location.pathname) {
            case '/object':
                this.setState({
                    menu: [
                        { text: 'Объект - проверки', route: '/object', isActive: true },
                        { text: 'Покупатель - проверки', route: '/buyer', isActive: false },
                        { text: 'Информация об объекте', route: '/analytics', isActive: false}
                    ]
                })
                break
            case '/buyer':
                this.setState({
                    menu: [
                        { text: 'Объект - проверки', route: '/object', isActive: false },
                        { text: 'Покупатель - проверки', route: '/buyer', isActive: true },
                        { text: 'Информация об объекте', route: '/analytics', isActive: false}
                    ]
                })
                break
            case '/analytics':
                this.setState({
                    menu: [
                        { text: 'Объект - проверки', route: '/object', isActive: false },
                        { text: 'Покупатель - проверки', route: '/buyer', isActive: false },
                        { text: 'Информация об объекте', route: '/analytics', isActive: true }
                    ]
                })
                break
            default:
                this.setState({
                    menu: [
                        { text: 'Объект - проверки', route: '/object', isActive: false },
                        { text: 'Покупатель - проверки', route: '/buyer', isActive: false },
                        { text: 'Информация об объекте', route: '/analytics', isActive: false}
                    ]
                })
                break
        }
    }

    toggleNavbar() {
        this.setState({
            ...this.state,
            ...{
                collapseOpen: !this.state.collapseOpen
            }
        });
    };

    render() {
        return (
            <Navbar type="dark" theme="primary" expand="md" style={{ marginBottom: '10px' }}>
                <NavbarBrand href="/home">Инесса</NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} />
                <Collapse open={this.state.collapseOpen} navbar>
                    <Nav navbar>
                        {this.state.menu.map((item, i) =>
                            <NavItem key={i}>
                                <NavLink active={item.isActive} href={item.route}>{item.text}</NavLink>
                            </NavItem>)}
                    </Nav>
                </Collapse>
            </Navbar>
        )
    }
}