import React from 'react';
import Navigation from '../components/Navigation'
import { NavLink as RouteNavLink } from "react-router-dom"
import StitchServices from '../services/stitch';
import {
    Badge,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Container,
    Row,
    Col,
    FormSelect,
    ButtonGroup,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormInput
} from "shards-react";
import { ReactDadata } from "react-dadata";
import plan from '../images/plan.png';

export default class Objects extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.formChanged = this.formChanged.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.addNewObject = this.addNewObject.bind(this);
        this.state = {
            createNew: false,
            objectsData: null,
            objectValue: '',
            sellerName: '',
            sellerPassportSeries: '',
            sellerPassportNumber: '',
            sellerIssueDate: '',
            sellerIssueBody: ''

        };
    };

    componentDidMount() {
        this._asyncRequest = StitchServices.getObjects().then(
            externalData => {
                this._asyncRequest = null;
                this.setState({
                    objectsData: externalData
                })
            }
        );
    };

    componentWillUnmount() {
        if (this._asyncRequest) {
            this._asyncRequest.cancel();
        }
    };

    toggle() {
        this.setState({
            createNew: !this.state.createNew
        })
    };

    closeModal() {
        this.setState({
            createNew: false,
            objectValue: '',
            sellerName: '',
            sellerPassportSeries: '',
            sellerPassportNumber: '',
            sellerIssueDate: '',
            sellerIssueBody: ''
        })
    };

    addNewObject() {
        this.setState({
            createNew: false
        })
    } ''

    formChanged(event) {
        switch (event.target.id) {
            case 'sellerName':
                this.setState({ sellerName: event.target.value });
                break
            case 'sellerPassportSeries':
                this.setState({ sellerPassportSeries: event.target.value });
                break
            case 'sellerPassportNumber':
                this.setState({ sellerPassportNumber: event.target.value });
                break
            case 'sellerIssueDate':
                this.setState({ sellerIssueDate: event.target.value });
                break
            case 'sellerIssueBody':
                this.setState({ sellerIssueBody: event.target.value });
                break
        }
    }

    render() {
        if (this.state.objectsData === null) {
            return (
                <div>
                    <Navigation></Navigation>
                </div>
            )
        } else {
            return (
                <div>
                    <Navigation></Navigation>
                    <Container fluid>
                        <Row>
                            <Col
                                className="main-content p-0"
                                lg={{ size: 10, offset: 1 }}
                                md={{ size: 9, offset: 3 }}
                                sm="12"
                                tag="main"
                            >
                                <Card small className="lo-stats h-100">
                                    <CardHeader className="border-bottom">
                                        <h6 className="m-0">Список проверок</h6>

                                    </CardHeader>

                                    <CardBody className="p-0">
                                        <Container fluid className="px-0">
                                            <table className="table mb-0">
                                                <thead className="py-2 bg-light text-semibold border-bottom">
                                                    <tr>
                                                        <th></th>
                                                        <th className="text-left">Адрес, ФИО продавца</th>
                                                        <th className="text-center">Результат</th>
                                                        <th className="text-center">Дата добавления</th>
                                                        <th className="text-center">Статус проверок</th>
                                                        <th className="text-right">Действия</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.objectsData.map((item, idx) => (
                                                        <tr key={idx}>
                                                            <td className="lo-stats__image">
                                                                <img alt='' src={plan} />
                                                            </td>
                                                            <td className="lo-stats__order-details" >
                                                                <RouteNavLink to={"/objectcheck"}>
                                                                    <span>{item.address}</span>
                                                                    <span>{item.seller}</span>
                                                                </RouteNavLink>
                                                            </td>
                                                            <td className="lo-stats__status">
                                                                <div className="d-table mx-auto">
                                                                    <Badge pill theme={getBadgeType(item.result)}>
                                                                        {item.result}
                                                                    </Badge>
                                                                </div>
                                                            </td>
                                                            <td className="lo-stats__items text-center">{item.dateAdded}</td>
                                                            <td className="lo-stats__total text-center">{item.checkStatus}</td>
                                                            <td className="lo-stats__actions">
                                                                <ButtonGroup className="d-table ml-auto">
                                                                    <Button size="sm" theme="white">Посмотреть</Button>
                                                                    <Button size="sm" theme="white">Закрыть</Button>
                                                                </ButtonGroup>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Container>
                                    </CardBody>
                                    <CardFooter className="border-top">
                                        <Row>
                                            <Col>
                                                <FormSelect size="sm" value="last-week" style={{ maxWidth: "130px" }} onChange={() => { }}>
                                                    <option value="all">Все</option>
                                                    <option value="active">Активные</option>
                                                    <option value="updated">Есть обновление</option>
                                                    <option value="closed">Закрытые</option>
                                                </FormSelect>
                                            </Col>
                                            <Col className="text-right view-report">
                                                <Button theme="primary" onClick={this.toggle}>Добавить объект</Button>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                        <Modal open={this.state.createNew} toggle={this.toggle} size="lg">
                            <ModalHeader>Добавить новый объект</ModalHeader>
                            <ModalBody>
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Адрес объекта</InputGroupText>
                                                </InputGroupAddon>
                                                <ReactDadata token="2b22eae11193756514c4e83ab149c7a484a726a2" query="Москва" placeholder="" onChange={this.objectSelected} value={this.state.objectValue}></ReactDadata>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Номер паспорта</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='sellerPassportNumber' onChange={this.formChanged} value={this.state.sellerPassportNumber} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Дата выдачи</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='sellerIssueDate' onChange={this.formChanged} value={this.state.sellerIssueDate} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText>Выдан</InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput id='sellerIssueBody' onChange={this.formChanged} value={this.state.sellerIssueBody} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            </ModalBody>
                            <ModalFooter>
                                <Button theme="secondary" onClick={this.closeModal}>Отменить</Button>
                                <Button theme="primary" onClick={this.addNewObject}>Добавить</Button>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </div>
            )
        }
    }
};

function getBadgeType(itemStatus) {
    var result;
    switch (itemStatus) {
        case 'Отлично': result = 'success';
            break
        case 'Хорошо': result = 'warning';
            break
        case 'Плохо': result = 'danger';
            break
        default: result = 'primary';
            break
    }
    return result;
}
