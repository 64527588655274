import React from "react";

import { NavLink as RouteNavLink } from "react-router-dom";

import {
    ListGroup,
    ListGroupItem,
    Button,
  Container,
  Row,
  Col
} from "shards-react";

export default class Deals extends React.Component {
    constructor(props) {
        super(props);
    };

    loadDeals() {
        let deals = [];
        for (let i = 0; i < this.props.profile.deals.length; i++) {            
            deals.push(<ListGroupItem action={true} tag={RouteNavLink} to={'/deals/' + this.props.profile.deals[i].id} key={this.props.profile.deals[i].id}>{this.props.profile.deals[i].objectValue}</ListGroupItem>)
        }
        return deals
    };

    render() {
        return (
            <Container>
                <Row style={{marginBottom: '10px'}}>
                    <Col>
                        <ListGroup>
                            {this.loadDeals()}
                        </ListGroup>
                    </Col>
                </Row>
                <Row style={{marginBottom: '10px'}}>
                    <Col>
                        <Button tag={RouteNavLink} to={"/deal/new"}>Создать новую</Button>
                    </Col>
                </Row>
            </Container>
        )
    }

}
