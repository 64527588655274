import React from 'react'
import { Route } from 'react-router-dom'

import Home from './Home'
import Deals from './Deals'
import Tasks from './Tasks'
import Deal from '../components/Deal'
import Login from './Login';
import Register from './Register';
import EmailConfirmation from './EmailConfirmation';
import PasswordReset from './PasswordReset';
import Objects from './Objects';
import Buyer from './Buyer';
import Analytics from './Analytics';
import ObjectCheck from './ObjectCheck';
import BuyerCheck from './BuyerCheck';
import StitchServices from '../services/stitch';

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/main.scss";

StitchServices.stitchAuth();

const profile = {
  name: 'Ken Adams'
};

const App = () => (

  <div>
    <Route exact path="/" component={() => <Login profile={profile} />} />
    <Route path="/home" component={() => <Home profile={profile} />} />
    <Route path="/object" component={() => <Objects profile={profile} />} />
    <Route path="/buyer" component={() => <Buyer profile={profile} />} />
    <Route path="/analytics" component={() => <Analytics profile={profile} />} />
    <Route path="/objectcheck" component={() => <ObjectCheck profile={profile} />} />
    <Route path="/buyercheck" component={() => <BuyerCheck profile={profile} />} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/reset" component={PasswordReset} />
    <Route path="/confirmation" component={EmailConfirmation} />
  </div>
)

export default App