import React from 'react'
import {
    ListGroup,
    ListGroupItem,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardSubtitle,
    Container,
    Row,
    Col,
    FormCheckbox,
    Badge
} from "shards-react";

export default class Tasks extends React.Component {
    constructor(props) {
        super(props);
    }

    loadChecks() {
        let checks = [];
        for (let i = 0; i < this.props.profile.deals.length; i++) {
            checks.push(
                <Row>
                    <Col>
                        <Card style={{ marginBottom: '10px' }}>
                            <CardHeader>
                                <CardTitle>{this.props.profile.deals[i].objectValue}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <CardSubtitle>Объект</CardSubtitle>
                                <ListGroup>
                                    <ListGroupItem>
                                        <Row>
                                            <Col lg="10"><FormCheckbox inline>Документ основания владения</FormCheckbox></Col>
                                            <Col lg="2"><Badge>Проверено</Badge></Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col lg="10"><FormCheckbox inline>Обременения на объекте</FormCheckbox></Col>
                                            <Col lg="2"><Badge>Надо проверить</Badge></Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col lg="10"><FormCheckbox inline>Собственником объекта указан продавец</FormCheckbox></Col>
                                            <Col lg="2"><Badge>Проверено</Badge></Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col lg="10"><FormCheckbox inline>Поэтажный план</FormCheckbox></Col>
                                            <Col lg="2"><Badge>Проверено</Badge></Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col lg="10"><FormCheckbox inline>Наличие прописанных на всю жизнь</FormCheckbox></Col>
                                            <Col lg="2"><Badge>Проверено</Badge></Col>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                            </CardBody><CardBody>
                                <CardSubtitle>Продавец</CardSubtitle>
                                <ListGroup>
                                    <ListGroupItem>
                                        <Row>
                                            <Col lg="10"><FormCheckbox inline>Наличие у продавца судимостей</FormCheckbox></Col>
                                            <Col lg="2"><Badge>Проверено</Badge></Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                    <Row>
                                            <Col lg="10"><FormCheckbox inline>Наличие продавца в списке банкротов</FormCheckbox></Col>
                                            <Col lg="2"><Badge>Проверено</Badge></Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                    <Row>
                                            <Col lg="10"><FormCheckbox inline>Имущество в браке</FormCheckbox></Col>
                                            <Col lg="2"><Badge>Проверено</Badge></Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                    <Row>
                                            <Col lg="10"><FormCheckbox inline>Доверенность актуальная</FormCheckbox></Col>
                                            <Col lg="2"><Badge>Проверено</Badge></Col>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )
        }
        return checks
    };


    render() {
        return (
            <Container>
                {this.loadChecks()}
            </Container>
        )
    }
}