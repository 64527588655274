import { Stitch, RemoteMongoClient, BSON, AnonymousCredential, UserPasswordAuthProviderClient, UserPasswordCredential } from 'mongodb-stitch-browser-sdk';
var stitchApp;

const StitchServices = {
    stitchAuth: function () {
        stitchApp = Stitch.initializeDefaultAppClient("inessa-rijdv");
        console.log(stitchApp);
    },
    loginUser: async function (login, pwd) {
        var result;
        const credential = new UserPasswordCredential(login, pwd);
        result = stitchApp.auth.loginWithCredential(credential)
            .then((authUser) => {
                console.log('success:', authUser);
                return 'success'
            })
            .catch(err => {
                console.log('error:', err);
                return 'error';
            })
        return result;
    },

    registerUser: async function (login, pwd) {
        var result;

        const emailPassClient = stitchApp.auth.getProviderClient(UserPasswordAuthProviderClient.factory);
        result = emailPassClient.registerWithEmail(login, pwd)
            .then(() => {
                console.log('success');
                return 'success';
            })
            .catch(err => {
                console.log('error registering user:', err);
                return 'error'
            });
        return result;
    },

    getObjects: async function () {
        const db = stitchApp.getServiceClient(RemoteMongoClient.factory, "mongodb-atlas").db('inessa');

        var data = [
            {
                id: 'oo1',
                address: "Кантемировская ул., д 12 к 1, кв .278",
                seller: "Елсуков Кирилл Андреевич",
                dateAdded: "10.08.2019",
                checkStatus: "3/10",
                result: "Хорошо"
            },
            {
                id: '002',
                address: "Коломенская ул., д 12 к 2, кв .146",
                seller: "Елсуков Кирилл Андреевич",
                dateAdded: "24.07.2019",
                checkStatus: "7/10",
                result: "Отлично"
            }
        ];

        /*
        stitchApp.auth.loginWithCredential(new AnonymousCredential()).then(user =>
            
            db.collection('objects').find().asArray()
          ).then(docs => {
              console.log("Found docs", docs)
              console.log("[MongoDB Stitch] Connected to Stitch")
              return docs;
          }).catch(err => {
              console.error(err)
          });
        */
        return data;
    },

    getObjectChecks: async function () {
        var data = [
            {
                id: 'co1',
                class: "Объект",
                name: "Документ основания владения",
                type: "Авто",
                result: "В процессе"
            },
            {
                class: "Объект",
                name: "Обременения на объекте",
                type: "Ручная",
                result: "Проверено"
            },
            {
                id: 'co2',
                class: "Объект",
                name: "Собственником объекта указан продавец",
                type: "Ручная",
                result: "В процессе"
            },
            {
                id: 'co3',
                class: "Объект",
                name: "Поэтажный план",
                type: "Авто",
                result: "Проверено"
            },
            {
                id: 'co4',
                class: "Объект",
                name: "Наличие прописанных на всю жизнь",
                type: "Ручная",
                result: "Проверено"

            },
            {
                id: 'cs1',
                class: "Продавец",
                name: "Наличие у продавца судимостей",
                type: "Ручная",
                result: "В процессе"

            },
            {
                id: 'cs2',
                class: "Продавец",
                name: "Наличие продавца в списке банкротов",
                type: "Авто",
                result: "Надо сделать"

            },
            {
                id: 'cs3',
                class: "Продавец",
                name: "Имущество в браке",
                type: "Авто",
                result: "Надо сделать"

            },
            {
                id: 'cs4',
                class: "Продавец",
                name: "Доверенность актуальная",
                type: "Ручная",
                result: "Проверено"

            }
        ];
        return data;
    },

    getBuyersChecks: async function () {
        var data = [
            {
                id: 'cb1',
                class: 'Покупатель',
                name: 'Поиск по судебным делам',
                type: 'Авто',
                result: 'Проверено'
            },
            {
                id: 'cb2',
                class: 'Покупатель',
                name: 'Проверка кредитной нагрузки',
                type: 'Авто',
                result: 'В процессе'
            },
            {
                id: 'cb3',
                class: 'Покупатель',
                name: 'Проверка налоговой нагрузки',
                type: 'Авто',
                result: 'В процессе'
            }
        ];
        return data;
    },

    getBuyersList: async function () {
        var data = [
            {
                id: 'bb1',
                buyerName: 'Елсуков Кирилл Андреевич',
                buyerPassportSeries: '',
                buyerPassportNumber: '',
                buyerIssueDate: '',
                buyerIssueBody: '',
                dateAdded: "24.07.2019",
                checkStatus: "7/10",
                result: "Отлично"
            },
            {
                id: 'bb2',
                buyerName: 'Елсуков Кирилл Андреевич',
                buyerPassportSeries: '',
                buyerPassportNumber: '',
                buyerIssueDate: '',
                buyerIssueBody: '',
                dateAdded: "10.08.2019",
                checkStatus: "3/10",
                result: "Хорошо"
            }
        ];
        return data;
    }
};

export default StitchServices;